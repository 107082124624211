

import Cookies from 'universal-cookie'; 

function IsLogin() {
  const cookies = new Cookies(); 
 let ASPNET_Cache = cookies.get('ASPNET_Cache');  
  return  ASPNET_Cache !==undefined;
}

function getUserCookie_ub36(){
  const cookies = new Cookies(); 
  let ub36 = cookies.get('ub36');  
  if(ub36 !==undefined) return ub36;
   return null ;
}

function getCookie(cname){
  const cookies = new Cookies(); 
  let c = cookies.get(cname);  
  if(c !==undefined) return c;
   return null ;
}


function CleanBackendCookies(callmemaybe) {
  const cookies = new Cookies(); 
  cookies.remove('ASPNET_Cache', { path: '/' });
  cookies.remove('UserDataJSON', { path: '/' });
  return callmemaybe();
}

export  {IsLogin, CleanBackendCookies,getUserCookie_ub36,getCookie}