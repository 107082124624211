import React from 'react';
import {  Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
//import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';

import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import LocalCafeOutlinedIcon from '@material-ui/icons/LocalCafeOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';

import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {['home',  'pricing'].map((text, index) => (
          <ListItem button key={text} component={Link}    to= { (text ==='home') ? '/': "/" +text}>
             <ListItemIcon>{index % 2 === 0 ? <HomeOutlinedIcon /> : <LoyaltyOutlinedIcon />}</ListItemIcon>  
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      {/* <List>
        {['Home', 'profile', 'news', 'pricing'].map((text, index) => (
          <ListItem button key={text} component="a"  href= { (text ==='Home') ? '/': "/" +text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>  
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
      <Divider />
      <List>
        {['about-us','safety', 'cookie-policy','privacy-policy','terms-of-service','refund-policy', 'contact'].map((text, index) => (
         <ListItem button key={text} component={Link}    to= { (text ==='home') ? '/': "/" +text}>
       {index % 7 === 0 ?<ListItemIcon><LocalCafeOutlinedIcon /></ListItemIcon>:''}
        {index % 7 === 1 ?<ListItemIcon><NotificationImportantOutlinedIcon /></ListItemIcon>:''}
        {index % 7 === 2 ?<ListItemIcon><PolicyOutlinedIcon /></ListItemIcon>:''}
        {index % 7 === 3 ?<ListItemIcon><HttpsOutlinedIcon /></ListItemIcon>:''}
        {index % 7 === 4 ?<ListItemIcon><GavelOutlinedIcon /></ListItemIcon>:''}
         {index % 7 === 5 ?<ListItemIcon><SettingsBackupRestoreIcon /></ListItemIcon>:''}
         {index % 7 === 6 ?<ListItemIcon><ContactMailOutlinedIcon /></ListItemIcon>:''}
         <ListItemText primary={text} />
       </ListItem>
        ))}
      </List>
    </div>
  );

  // const fullList = side => (
  //   <div
  //     className={classes.fullList}
  //     role="presentation"
  //     onClick={toggleDrawer(side, false)}
  //     onKeyDown={toggleDrawer(side, false)}
  //   >
  //     <List>
  //       {['Inbox',  'pricing'].map((text, index) => (
  //         <ListItem button key={text} component={Link}    to= { (text ==='Home') ? '/': "/" +text}>
  //         <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
  //         <ListItemText primary={text} />
  //       </ListItem>
  //       ))}
  //     </List>
  //           <Divider />
  //     <List>
  //       {['about-us','safety', 'cookie-policy','privacy-policy','terms-of-service', 'contact'].map((text, index) => (
  //         <ListItem button key={text} component={Link}    to={ (text ==='Home') ? '/': "/" +text}>
  //         <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
  //         <ListItemText primary={text} />
  //       </ListItem>
  //       ))}
  //     </List>
  //   </div>
  // );

  return (
    <div>
            <IconButton 
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer" 
            onClick={toggleDrawer('left', true)}
            >
            <MenuIcon />  
          </IconButton>
     
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
      {/* <SwipeableDrawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer('top', false)}
        onOpen={toggleDrawer('top', true)}
      >
        {fullList('top')}
      </SwipeableDrawer> */}
      {/* <SwipeableDrawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer('bottom', false)}
        onOpen={toggleDrawer('bottom', true)}
      >
        {fullList('bottom')}
      </SwipeableDrawer> */}
      {/* <SwipeableDrawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {sideList('right')}
      </SwipeableDrawer> */}
    </div>
  );
}
