//React code
//https://www.codegrepper.com/code-examples/javascript/multiple+image+upload+react



// useState https://itnext.io/add-state-and-lifecycle-methods-to-function-components-with-react-hooks-8e2bdc44d43d
 import React, { Suspense, lazy   }   from 'react'; 
 
// import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch   } from 'react-router-dom';

  

//https://www.npmjs.com/package/react-cookie
//import { CookiesProvider, useCookies  } from 'react-cookie';

 
 import CssBaseline from '@material-ui/core/CssBaseline';
//import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

//24-May-2021 import { makeStyles } from '@material-ui/core/styles';

 //Docs
//Lazy loading
//https://reactjs.org/docs/code-splitting.html

 //https://www.golangprograms.com/how-to-create-simple-react-router-to-navigate-multiple-pages.html

 //dinamic styling
 //https://www.joyk.com/dig/detail/1532442610646595#parameters

 //add theme
 //https://material-ui.com/customization/color/
 //https://material-ui.com/customization/palette/
 //https://www.materialpalette.com/blue/amber
 //implement that
 //https://material-ui.com/customization/breakpoints/

 //dynamic Media queries
 //https://stackoverflow.com/questions/58305558/dynamic-media-querie-with-react-hooks

//import { makeStyles ,createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';

 import './App.css';

//import { __makeStyles, theme, Tp_themeProvider } from "./Components/Theme/Theme"
import {   theme, Tp_themeProvider } from "./Components/Theme/Theme"


import AppBar from './Components/AppBar/AppBar';
import Error from './Components/ErrorPage/Error';


//import Cards from './Components/Cards/Cards';
import FooterBottomNavigation from './Components/FooterBottomNavigation/FooterBottomNavigation'
 //import { palette, spacing, typography } from '@material-ui/system';

 //https://github.com/iamhosseindhv/notistack
 import { SnackbarProvider } from 'notistack';

//import MainPage from  "./Components/MainPage/MainPage";
//import Pricing from  "./StaticPages/Pricing";
//import AboutUs from  "./StaticPages/AboutUs";
 
//import Disclamer from  "./StaticPages/Disclamer";
//import CookiePolicy from  "./StaticPages/CookiePolicy";
//import PrivacyPolicy from  "./StaticPages/PrivacyPolicy";
 
//import TermsAndConditions from  "./StaticPages/TermsAndConditions";
//import Contact from  "./StaticPages/Contact";




 
 
//24-May-2021
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;



const MainPage = lazy(() => import('./Components/MainPage/MainPage'));
// const BlobsPage = lazy(() => import('./Components/MainPage/mainSearchBlobs'));

const AboutUs = lazy(() => import('./StaticPages/AboutUs'));
//const NewsPage = lazy(() => import('./News/News'));
const Pricing = lazy(() => import('./StaticPages/Pricing'));
const ErrorPage = lazy(() => import('./StaticPages/Error'));
const Safety = lazy(() => import('./StaticPages/Safety'));
const CookiePolicy = lazy(() => import('./StaticPages/CookiePolicy'));
const PrivacyPolicy = lazy(() => import('./StaticPages/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./StaticPages/RefundPolicy'));
const TermsAndConditions = lazy(() => import('./StaticPages/TermsAndConditions'));
const Contact = lazy(() => import('./StaticPages/Contact')); 
const Support = lazy(() => import('./StaticPages/Support')); 
 
const Newsletters = lazy(() => import('./StaticPages/Newsletters')); 
const Signup = lazy(() => import('./StaticPages/SignupPage')); 
const HomePage = lazy(() => import('./StaticPages/HomePage')); 
const LoginPage = lazy(() => import('./StaticPages/LoginPage')); 
const LogoutPage = lazy(() => import('./StaticPages/LogoutPage')); 
const RestorepasswordPage = lazy(() => import('./StaticPages/RestorepasswordPage')); 
const ChangepasswordPage = lazy(() => import('./StaticPages/ChangepasswordPage')); 
const ActivatePage = lazy(() => import('./StaticPages/ActivatePage')); 
const ActivateinviteuserPage = lazy(() => import('./StaticPages/ActivateinviteuserPage')); 
 
const Search = lazy(() => import('./Portal/SearchPage'));
const ProfilePage = lazy(() => import('./Portal/ProfilePage.js'));
const MessagesPage = lazy(() => import('./Portal/MessagesPage.js'));
 
const InboxPage = lazy(() => import('./Portal/InboxPage.js')); 
const MyLikesPage = lazy(() => import('./Portal/MyLikesPage.js')); 
const WhoLikedMePage = lazy(() => import('./Portal/WhoLikedMePage.js')); 
 
const WhosViewMePage = lazy(() => import('./Portal/WhosViewMePage.js')); 
const MyInvitesPage = lazy(() => import('./Portal/MyInvitesPage.js')); 
const InvitationsPage = lazy(() => import('./Portal/InvitationsPage.js')); 
const EditProfilePage = lazy(() => import('./Portal/EditProfilePage.js'));
const AccountsettingsPage = lazy(() => import('./Portal/AccountsettingsPage.js'));
const MyAccountInitialSetupPage = lazy(() => import('./Portal/MyAccountInitialSetupPage.js')); 
const SettingsPage = lazy(() => import('./Portal/SettingsPage.js'));
 
const MembershipPage = lazy(() => import('./Portal/MembershipPage')); 
// const PayWithStripePage = lazy(() => import('./Portal/membership/paywithstripe')); 
const PayWithSquarePage = lazy(() => import('./Portal/membership/paywithsquare')); 
const PayWithPayPalPage = lazy(() => import('./Portal/membership/paywithpaypal')); 

 
//https://web.dev/code-splitting-suspense/?utm_source=lighthouse&utm_medium=devtools
//const renderLoader = () => <p>Loading</p>;
 


 //https://www.materialpalette.com/blue/amber
// usage https://material-ui.com/customization/color/
 
 /*
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #FFC107; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #757575; }
.divider-color         { border-color: #BDBDBD; }
*/





/* https://www.cnbc.com/ 
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4c81c5',
      main: '#005594',
      dark: '#002d66',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffeb6d',
      main: '#EAB93B',
      dark: '#b48900',
      contrastText: '#000',
    },
  },
});  */

/* Amazon 
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4987b7',
      main: '#232F3E',
      dark: '#00315a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffeb6d',
      main: '#EAB93B',
      dark: '#b48900',
      contrastText: '#000',
    },
  },
}); 

end Amazon */


//24-May-2021 
// const useStyles = makeStyles({
//   root: { 
    
//   },
//   stickToBottom: {
//     width: '100%',
//      position: 'fixed',
//       bottom: 0,
//       height: 56,
//       margin:0,
//   }, 
  
// });
 
 


/* 
ReactDOM.render(
    routes,
    document.getElementById('root')
  );
*/

//https://stackoverflow.com/questions/41956465/how-to-create-multiple-page-app-using-react  
/*ReactDOM.render(
   
  routes,
  document.getElementById('root')
);*/

 

 

// const useStyles = __makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//      margin: theme.spacing(0),   
// maxWidth:968,

//   },
//   paper: {
//     height: 220,
//      width: 240, 
//   },
//   control: {
//     padding: theme.spacing(0),
//   },
// }));

 
  
 

function App() {  
 
    //const classes = useStyles(); 
 
    //https://reactrouter.com/web/api/Hooks/uselocation
      


    // https://medium.com/@weberzt/importing-a-google-font-into-your-react-app-using-material-ui-773760ded532
    // https://fonts.google.com/specimen/Poppins
    // const font =  "'Poppins', sans-serif";
    // const theme = createMuiTheme({
    //   typography: {
    //     fontFamily: font,
    //     button: 
    //       textTransform: "none"
    //     }
    //   }
    // });


  return ( 
 
  <div >
    
      <Tp_themeProvider theme={theme}> 
        <BrowserRouter>
       
    {/* 
     <CookiesProvider>
    
    <Helmet>
    <title>Turbo Todo</title>
    <meta name="description" content="Todos on steroid!" />
    <meta name="theme-color" content="#008f68" />
  </Helmet> */}

  <SnackbarProvider maxSnack={3}>
  
    <div className="App">  
      <header >
        <AppBar />
      </header>
           <React.Fragment>
      <CssBaseline /> 
      <main   > 
      <Container maxWidth="lg">
       <Box>

    
       <Suspense fallback={<div></div>}>
      <Switch    > 
         <Route path="/search" component={MainPage} exact/> 

         <Route path="/" component={HomePage} exact/>
         <Route path="/index.html" component={HomePage} exact/> 
        
         <Route exact  path="/pricing" component={Pricing}/> 
         <Route exact  path="/about-us" component={AboutUs}/>  
         <Route exact  path="/safety" component={Safety}/>  
          
         <Route exact  path="/cookie-policy" component={CookiePolicy}/>  
         <Route exact  path="/privacy-policy" component={PrivacyPolicy}/>  
         <Route exact  path="/refund-policy" component={RefundPolicy}/>  
          
         <Route exact  path="/terms-of-service" component={TermsAndConditions}/>  
         <Route exact  path="/contact" component={Contact}/>  
         <Route exact  path="/support" component={Support}/> 
          
         <Route exact  path="/newsletters" component={Newsletters}/>  
          
         <Route exact  path="/signup" component={Signup}/>  
         <Route exact  path="/login" component={LoginPage}/>  
         <Route exact  path="/logout" component={LogoutPage}/> 
          <Route exact  path="/restorepassword/changepassword" component={ChangepasswordPage}/>     
         <Route exact  path="/restorepassword" component={RestorepasswordPage}/>  
               
         <Route exact  path="/activate" component={ActivatePage}/> 
         <Route exact  path="/activateinviteuser" component={ActivateinviteuserPage}/> 
          
         <Route exact    path="/search" component={Search}/>  
         <Route exact    path="/profile" component={ProfilePage} />   
         <Route exact    path="/profile/:id" component={ProfilePage}  />  

         <Route exact    path="/messages" component={MessagesPage} />   
         <Route exact    path="/messages/:id" component={MessagesPage}  />  

         <Route exact     path="/inbox" component={InboxPage}/>  
         <Route exact     path="/mylikes" component={MyLikesPage}/>   
         <Route exact     path="/whoslikedme" component={WhoLikedMePage}/>   
          
         <Route exact     path="/myinvites" component={MyInvitesPage}/> 
         <Route exact     path="/invitations" component={InvitationsPage}/> 
         <Route exact    path="/editprofile" component={EditProfilePage}/>   
         <Route exact    path="/whosviewedme" component={WhosViewMePage}/>  
          
         <Route exact    path="/accountinitialsetup" component={MyAccountInitialSetupPage}/>  
             <Route exact    path="/accountsettings" component={AccountsettingsPage}/>   
             <Route exact    path="/settings" component={SettingsPage}/>   
             <Route exact    path="/membership" component={MembershipPage}/>                 
             {/* <Route exact    path="/membership/paywithstripe" component={PayWithStripePage}/>   */}
             <Route exact    path="/membership/paywithsquare" component={PayWithSquarePage}/>  
             <Route exact    path="/membership/paywithpaypal" component={PayWithPayPalPage}/>  
               
  
        
        <Route component={Error}/>
        <Route exact  path="/error" component={ErrorPage} isAuthed={true}/> 
       </Switch>
       </Suspense>

</Box>
</Container> 
     
      </main> 
  
    </React.Fragment> 
 
 <footer  className="stickToBottom" >
   <FooterBottomNavigation   />
 </footer>
  
    </div>
  
        </SnackbarProvider>
 
     </BrowserRouter>
    </Tp_themeProvider>
  
</div>


  );
}

export default App;
