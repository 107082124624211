import { makeStyles ,createMuiTheme, ThemeProvider  } from '@material-ui/core/styles';

/* https://www.cnbc.com/  */
export const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#72f6ff',
        main: '#21c3fd',
        dark: '#0093ca',
        contrastText: '#fff',
      },
      secondary: {
        light: '#fff8ff',
        main: '#ffc5e1',
        dark: '#cb94af',
        contrastText: '#000',
      },
    },
  }); 

  export const Tp_themeProvider=ThemeProvider;

  export const __makeStyles=makeStyles;